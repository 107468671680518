import dynamic from 'next/dynamic';

export default {
    Page404: dynamic(() => import('./Page404')),
    PageApply: dynamic(() => import('./PageApply')),
    PageApplyComplete: dynamic(() => import('./PageApplyComplete')),
    PageArchive: dynamic(() => import('./PageArchive')),
    PageArchiveSchool: dynamic(() => import('./PageArchiveSchool')),
    PageArticle: dynamic(() => import('./PageArticle')),
    PageArticleSchool: dynamic(() => import('./PageArticleSchool')),
    PageArticleSearch: dynamic(() => import('./PageArticleSearch')),
    PageCalendar: dynamic(() => import('./PageCalendar')),
    PageCalendarEvent: dynamic(() => import('./PageCalendarEvent')),
    PageCampaign: dynamic(() => import('./PageCampaign')),
    PageCity: dynamic(() => import('./PageCity')),
    PageCollaboration: dynamic(() => import('./PageCollaboration')),
    PageContact: dynamic(() => import('./PageContact')),
    PageCustomSearchSchool: dynamic(() => import('./PageCustomSearchSchool')),
    PageEvent: dynamic(() => import('./PageEvent')),
    PageEventSchool: dynamic(() => import('./PageEventSchool')),
    PageEventSearch: dynamic(() => import('./PageEventSearch')),
    PageEventSearchKulturama: dynamic(() => import('./PageEventSearchKulturama')),
    PageEventSearchSchool: dynamic(() => import('./PageEventSearchSchool')),
    PageGlobalSearch: dynamic(() => import('./PageGlobalSearch')),
    PageHome: dynamic(() => import('./PageHome')),
    PageHomeKulturama: dynamic(() => import('./PageHomeKulturama')),
    PageHomeSchool: dynamic(() => import('./PageHomeSchool')),
    PageLanding: dynamic(() => import('./PageLanding')),
    PageLandingKulturama: dynamic(() => import('./PageLandingKulturama')),
    PageLandingSchool: dynamic(() => import('./PageLandingSchool')),
    PageNews: dynamic(() => import('./PageNews')),
    PageOffer: dynamic(() => import('./PageOffer')),
    PageOfferKulturama: dynamic(() => import('./PageOfferKulturama')),
    PageOfferSchool: dynamic(() => import('./PageOfferSchool')),
    PageSectionKulturama: dynamic(() => import('./PageSectionKulturama')),
    PageSectionTillskararakademin: dynamic(() => import('./PageSectionTillskararakademin')),
    PageSubject: dynamic(() => import('./PageSubject')),
    PageSubjectSchool: dynamic(() => import('./PageSubjectSchool')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
};
